import React from "react";
import styles from "./place-modal.module.scss";
import getPanoramaImageUrl from "../../modules/get-panorama-image-url";
import layout from "../../style/layout.module.scss";

class PlaceModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            values: { }
        };
    }

    getCurrentName() {
        const { editingPlace } = this.props;
        const { values } = this.state;
        const nameValue = values.name !== null && values.name !== undefined
            ? values.name
            : (editingPlace && editingPlace.name) || "";

        return nameValue;
    }

    getCurrentXY() {
        const { center_x, center_y } = this.state.values;
        const { editingPlace } = this.props;
        const x = center_x || (editingPlace !== null && editingPlace.center_x) || null;
        const y = center_y || (editingPlace !== null && editingPlace.center_y) || null;
        return { x, y };
    }

    renderHeading() {
        const { editingPlace } = this.props;
        const content = editingPlace
            ? "Editing place"
            : "New place"
        return (
            <h4>{content}</h4>
        )
    }

    renderInstructions() {
        return (
            <p className={styles.instructions}>
                Click on the image below to position this place.
            </p>
        )
    }

    getImageXYFromEvent = (e) => {
        const rect = e.target.getBoundingClientRect();
        const xPx = e.clientX - rect.left;
        const yPx = e.clientY - rect.top;
        const x = xPx / rect.width;
        const y = yPx / rect.height;

        return { x, y };
    }

    handleImageMouseDown = (e) => {
        const { x, y } = this.getImageXYFromEvent(e);
        this.setState({
            dragging: true,
            values: {
                ...this.state.values,
                center_x: x,
                center_y: y,
            }
        })
    }

    handleImageMouseMove = (e) => {
        if (this.state.dragging) {
            const { x, y } = this.getImageXYFromEvent(e);
            this.setState({
                values: {
                    ...this.state.values,
                    center_x: x,
                    center_y: y,
                }
            })
        }
    }

    handleImageMouseUp = () => {
        this.setState({
            dragging: false,
        })
    }

    renderOtherMarkers() {
        const { places } = this.props.panorama;
        const { editingPlace } = this.props;
        return places
        .filter(place => 
            (place.center_x !== null && place.center_y !== null) &&
            (!editingPlace || editingPlace.id !== place.id)
        )
        .map(place => {
            const x = place.center_x;
            const y = place.center_y;
            return (
                <div
                    className={styles.panoramaOtherMarker}
                    key={place.id || place.name}
                    style={{
                        left: (x * 100) + "%",
                        top: (y * 100) + "%",
                    }}
                />
            )
        })
    }

    renderImageMarker() {
        const { center_x, center_y } = this.state.values;
        const { editingPlace } = this.props;

        const x = center_x || (editingPlace !== null && editingPlace.center_x);
        const y = center_y || (editingPlace !== null && editingPlace.center_y);

        if (!x || !y) return;

        return (
            <div
                className={styles.panoramaImageMarker}
                style={{
                    left: (x * 100) + "%",
                    top: (y * 100) + "%",
                }}
            />
        )
    }

    renderImage() {
        const { panorama, imageHash, env } = this.props;
        const { name } = panorama;
        return (
            <div className={styles.panoramaImageContainer}>
                <img
                    alt={name}
                    draggable={false}
                    className={styles.panoramaImage}
                    src={getPanoramaImageUrl(panorama, env, imageHash)}
                    onMouseDown={this.handleImageMouseDown}
                    onMouseMove={this.handleImageMouseMove}
                    onMouseUp={this.handleImageMouseUp}
                />
                {this.renderImageMarker()}
                {this.renderOtherMarkers()}
            </div>
        );
    }

    renderForm() {
        const { editingPlace } = this.props;
        const { values } = this.state;
        const nameValue = values.name !== null && values.name !== undefined
            ? values.name
            : (editingPlace && editingPlace.name) || "";

        return (
            <form className={styles.modalForm}>
                <div className={layout.row}>
                    <label>Name</label>
                </div>
                <div className={layout.row}>
                    <input type="text" placeholder="Enter a name..." value={nameValue} onChange={(e) => {
                        this.setState({
                            values: {
                                ...values,
                                name: e.target.value,
                            }
                        })  
                    }}/>
                </div>
            </form>
        )
    }

    handleSubmit = () => {
        const { editingPlace, onPlaceEdited, onPlaceCreated } = this.props;
        if (editingPlace) {
            const returnObject = {
                ...editingPlace,
                ...this.state.values,
            }
            onPlaceEdited(returnObject);
        } else {
            const returnObject = this.state.values;
            onPlaceCreated(returnObject);
        }
        // Clear form values.
        this.setState({ values: {} });
    }

    handleCloseButtonClick = () => {
        this.props.onCloseClick();
        this.setState({ values: {} });
    }

    handleDeleteButtonClick = () => {
        const { onPlaceDeleted, editingPlace } = this.props;

        if (
            window.confirm(`Are you sure you want to delete "${editingPlace.name}"?`)
        ) {
            onPlaceDeleted(editingPlace);
            this.setState({ values: {} });
        }
    }

    isSubmitEnabled() {
        const { editingPlace, panorama } = this.props;
        const nameValue = this.getCurrentName();
        const { x, y } = this.getCurrentXY();

        if (x === null || y === null) {
            return false;
        }

        // See if any other places using this item
        const otherItems = editingPlace
            ? panorama.places.filter(p => p.id !== editingPlace.id)
            : panorama.places;

        if (otherItems.some(place => place.name === nameValue)) {
            return false;
        }
        
        // If name isn't empty we're ok
        if (nameValue) return true;

        return false;
    }

    renderButtons() {

        const submitEnabled = this.isSubmitEnabled();

        const deleteButton = this.props.editingPlace ? (
            <button
                onClick={this.handleDeleteButtonClick}
                className={styles.deleteButton + " danger"}
            >
                Delete
            </button>
        ) : null;

        // TODO: use a real icon
        return (
            <div className={styles.buttonContainer}>
                <button
                    className={styles.saveButton}
                    onClick={this.handleSubmit}
                    disabled={!submitEnabled}
                >
                    Save
                </button>
                {deleteButton}
                <button
                    onClick={this.handleCloseButtonClick}
                    className={styles.closeButton + " light"}
                >
                    X
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className={styles.modal}>
                <div className={layout.row}>
                    {this.renderHeading()}
                </div>
                <div className={layout.row}>
                    {this.renderInstructions()}
                </div>
                <div className={layout.row}>
                    {this.renderImage()}
                </div>
                <div className={layout.row}>
                    {this.renderForm()}
                </div>
                <div className={layout.row}>
                    {this.renderButtons()}
                </div>
            </div>
        );
    }
}

export default PlaceModal;
