import React from 'react';
import ReactDOM from 'react-dom';
import styles from './modal-wrapper.module.scss';

class ModalWrapper extends React.Component {
  componentWillMount() {
    this.el = document.createElement('div');
    this.el.className = styles.modalWrapper;
    document.body.appendChild(this.el);
  }

  componentDidMount() {
    this.updateVisibility();
  }

  componentDidUpdate() {
    this.updateVisibility();
  }

  updateVisibility() {
    if (this.props.children && this.props.isVisible) {
      this.el.setAttribute('data-visible', true);
    } else {
      this.el.setAttribute('data-visible', false);
    }
  }

  componentWillUnmount() {
    this.el.parentElement.removeChild(this.el);
  }
  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

ModalWrapper.defaultProps = {
  isVisible: true,
};

export default ModalWrapper;
