import React from "react";

const Ripple = props => (
    <svg
        width={67}
        height={67}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-ripple"
        style={{ background: "0 0" }}
        {...props}
    >
        <circle
            cx={50}
            cy={50}
            r={34.02}
            fill="none"
            stroke="currentColor"
            strokeWidth={4}
        >
            <animate
                attributeName="r"
                calcMode="spline"
                values="0;40"
                keyTimes="0;1"
                dur={1.5}
                keySplines="0 0.2 0.8 1"
                begin="-0.75s"
                repeatCount="indefinite"
            />
            <animate
                attributeName="opacity"
                calcMode="spline"
                values="1;0"
                keyTimes="0;1"
                dur={1.5}
                keySplines="0.2 0 0.8 1"
                begin="-0.75s"
                repeatCount="indefinite"
            />
        </circle>
        <circle
            cx={50}
            cy={50}
            r={14.728}
            fill="none"
            stroke="currentColor"
            opacity={0.9}
            strokeWidth={4}
        >
            <animate
                attributeName="r"
                calcMode="spline"
                values="0;40"
                keyTimes="0;1"
                dur={1.5}
                keySplines="0 0.2 0.8 1"
                begin="0s"
                repeatCount="indefinite"
            />
            <animate
                attributeName="opacity"
                calcMode="spline"
                values="1;0"
                keyTimes="0;1"
                dur={1.5}
                keySplines="0.2 0 0.8 1"
                begin="0s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);

export default Ripple;
