import React, { Component } from "react";
import { connect } from "react-redux";
import {
    selectComments,
    fetchComments,
    updateCommentStatus,
} from "../../ducks/comment";
import styles from "./comment-table.module.scss";
import ReactTable from "react-table";
import "react-table/react-table.css";

class CommentTable extends Component {
    componentDidMount() {
        const query = `panoramaId=${this.props.panorama.id}&includeUpvotes=true`;
        this.props.fetchComments(query);
    }

    renderApproveBtn(id) {
        return (
            <button
                onClick={() => this.props.updateCommentStatus(id, "Approved")}
                className="button small"
            >
                Approve
            </button>
        );
    }
    renderRemoveBtn(id) {
        return (
            <button
                onClick={() => this.props.updateCommentStatus(id, "Removed")}
                className="button small danger"
            >
                Delete
            </button>
        );
    }
    render() {
        const { comments } = this.props;
        return (
            <ReactTable
                data={comments}
                noDataText="No comments have been made yet."
                className={styles.table + " -striped -highlight"}
                columns={[
                    {
                        Header: "Content",
                        accessor: "content",
                    },
                    {
                        Header: "Email",
                        id: "email",
                        maxWidth: 200,
                        accessor: (comment) => comment.user && comment.user.email,
                    },
                    {
                        Header: "Time",
                        id: "time",
                        maxWidth: 160,
                        accessor: (comment) => new Date(comment.created_at).toLocaleString(),
                    },
                    {
                        Header: "Votes",
                        id: "upvotes",
                        accessor: (comment) => comment.upvotes && comment.upvotes.length,
                        maxWidth: 60,
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        maxWidth: 90,
                    },
                    {
                        Header: "Approval",
                        id: "admin",
                        maxWidth: 90,
                        accessor: ({ manually_moderated }) =>
                            manually_moderated ? "Manual" : "Automatic",
                    },
                    {
                        Header: "Actions",
                        accessor: "status",
                        width: 180,
                        Cell: ({
                            row: {
                                _original: { id, status },
                            },
                        }) => (
                            <div className={styles.btnWrapper}>
                                {status === "Approved"
                                    ? null
                                    : this.renderApproveBtn(id)}{" "}
                                {status === "Removed"
                                    ? null
                                    : this.renderRemoveBtn(id)}
                            </div>
                        ),
                    },
                ]}
                defaultPageSize={20}
            />
        );
    }
}

const mapStateToProps = state => ({
    comments: selectComments(state),
    loading: state.comment.requesting,
});
const mapDispatchToProps = {
    fetchComments,
    updateCommentStatus,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentTable);
