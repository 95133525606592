import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import Root from './pages/root';
import * as serviceWorker from './serviceWorker';
import createStore from "./store";
import "./style/index.scss";

const upgradeId = window.location.hash.replace("#", "") || 1;

const store = createStore({
    auth: { token: "development", env: "dev" },
    upgrade: { currentUpgrade: upgradeId },
});
const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Root />
        </Provider>,
        document.getElementById("root")
    );
};

render();

if (module.hot) {
    module.hot.accept("./pages/root", function() {
        render();
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
