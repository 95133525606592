import { createSelector } from "reselect";
import  makeRequest from "../modules/make-request";
import { selectRequestParameters } from "./auth";

const PANORAMA_REQUEST = "tfnsw-tap/panorama/PANORAMA_REQUEST";
const PANORAMA_SUCCESS = "tfnsw-tap/panorama/PANORAMA_SUCCESS";
const PANORAMA_ERROR = "tfnsw-tap/panorama/PANORAMA_ERROR";

const PANORAMA_UPDATE_REQUEST = "tfnsw-tap/panorama/PANORAMA_UPDATE_REQUEST";
const PANORAMA_UPDATE_SUCCESS = "tfnsw-tap/panorama/PANORAMA_UPDATE_SUCCESS";
const PANORAMA_UPDATE_ERROR = "tfnsw-tap/panorama/PANORAMA_UPDATE_ERROR";

const PLACE_UPDATE_REQUEST = "tfnsw-tap/panorama/PLACE_UPDATE_REQUEST";
const PLACE_UPDATE_SUCCESS = "tfnsw-tap/panorama/PLACE_UPDATE_SUCCESS";
const PLACE_UPDATE_ERROR = "tfnsw-tap/panorama/PLACE_UPDATE_ERROR";

const PLACE_CREATE_REQUEST = "tfnsw-tap/panorama/PLACE_CREATE_REQUEST";
const PLACE_CREATE_SUCCESS = "tfnsw-tap/panorama/PLACE_CREATE_SUCCESS";
const PLACE_CREATE_ERROR = "tfnsw-tap/panorama/PLACE_CREATE_ERROR";

const PLACE_DELETE_REQUEST = "tfnsw-tap/panorama/PLACE_DELETE_REQUEST";
const PLACE_DELETE_SUCCESS = "tfnsw-tap/panorama/PLACE_DELETE_SUCCESS";
const PLACE_DELETE_ERROR = "tfnsw-tap/panorama/PLACE_DELETE_ERROR";

const IMAGE_UPLOAD_REQUEST = "tfnsw-tap/panorama/IMAGE_UPLOAD_REQUEST";
const IMAGE_UPLOAD_SUCCESS = "tfnsw-tap/panorama/IMAGE_UPLOAD_SUCCESS";
const IMAGE_UPLOAD_ERROR = "tfnsw-tap/panorama/IMAGE_UPLOAD_ERROR";

const initialState = {
    panoramas: { },
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case PANORAMA_REQUEST: {
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [action.payload.panoramaId]: {
                        requesting: true,
                        success: false,
                        error: null,
                    }
                }
            };
        }
        case PANORAMA_ERROR: {
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [action.payload.panoramaId]: {
                        requesting: false,
                        success: false,
                        error: action.payload.error,
                    }
                },
            };
        }
        case PANORAMA_SUCCESS: {
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [action.payload.panoramaId]: {
                        requesting: false,
                        success: true,
                        error: null,
                        data: action.payload.data,
                        imageUploading: false,
                        imageSuccess: false,
                        imageError: null,
                    }
                },
            };
        }


        case PANORAMA_UPDATE_REQUEST: {
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [action.payload.panoramaId]: {
                        ...state.panoramas[action.payload.panoramaId],
                        data: {
                            ...state.panoramas[action.payload.panoramaId].data,
                            ...action.payload.panorama,
                        }
                    }
                }
            };
        }
        case PANORAMA_UPDATE_ERROR: {
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [action.payload.panoramaId]: {
                        requesting: false,
                        success: false,
                        error: action.payload.error,
                    }
                },
            };
        }
        case PANORAMA_UPDATE_SUCCESS: {
            // Update optimistically.
            // Server doesn't currently return anything useful anyway.
            return state;
        }


        case PLACE_UPDATE_REQUEST: {
            const { panorama, updatedPlace } = action.payload;

            const newPlaces = panorama.places.map(
                place => place.id === updatedPlace.id
                    ? updatedPlace
                    : place
            );

            const newData = {
                ...panorama,
                places: newPlaces,
            }

            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        data: newData,
                    }
                }
            };
        }
        case PLACE_UPDATE_ERROR: {
            // Currently don't do anything here,
            // but _should_ freak out.
            return state;
        }
        case PLACE_UPDATE_SUCCESS: {
            // Currently don't do anything here.
            return state;
        }

        case PLACE_CREATE_REQUEST: {
            const { panorama, newPlace } = action.payload;

            // We just insert our new place here.
            // When we get a success, we replace it (because we get the ID back).
            const newPlaces = panorama.places.concat([newPlace]);

            const newData = {
                ...panorama,
                places: newPlaces,
            }

            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        data: newData,
                    }
                }
            };
        }
        case PLACE_CREATE_ERROR: {
            // Currently don't do anything here,
            // but _should_ freak out.
            return state;
        }
        case PLACE_CREATE_SUCCESS: {
            const { newPlace } = action.payload;

            const panorama = state.panoramas[action.payload.panorama.id].data;

            const newPlaces = panorama.places.map(
                place => place.name === newPlace.name
                    ? newPlace
                    : place
            );
            
            const newData = {
                ...panorama,
                places: newPlaces,
            }

            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        data: newData,
                    }
                }
            };
        }

        case PLACE_DELETE_REQUEST: {
            const { panorama, deletedPlace } = action.payload;

            const newPlaces = panorama.places.slice().filter(
                place => place.id !== deletedPlace.id
            );

            const newData = {
                ...panorama,
                places: newPlaces,
            }

            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        data: newData,
                    }
                }
            };
        }
        case PLACE_DELETE_ERROR: {
            // Currently don't do anything here,
            // but _should_ freak out.
            return state;
        }
        case PLACE_DELETE_SUCCESS: {
            // Currently don't do anything here.
            return state;
        }

        case IMAGE_UPLOAD_REQUEST: {
            const { panorama } = action.payload;
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        imageUploading: true,
                        imageSuccess: false,
                        imageError: null,
                    }
                }
            };
        }
        case IMAGE_UPLOAD_ERROR: {
            const { panorama, error } = action.payload;
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        imageUploading: true,
                        imageSuccess: false,
                        imageError: error,
                    }
                },
            };
        }
        case IMAGE_UPLOAD_SUCCESS: {
            const { panorama, imageUrl } = action.payload;
            return {
                ...state,
                panoramas: {
                    ...state.panoramas,
                    [panorama.id]: {
                        ...state.panoramas[panorama.id],
                        data: {
                            ...state.panoramas[panorama.id].data,
                            panorama_image_url: imageUrl,
                        },
                        imageUploading: false,
                        imageSuccess: true,
                        imageError: null,
                    }
                },
            };
        }

        default:
            return state;
    }
}

// -----------------
// Action creators

export function panoramaRequest(panoramaId) {
    return { type: PANORAMA_REQUEST, payload: {
        panoramaId,
    } };
}
export function panoramaSuccess(panoramaId, data) {
    return { type: PANORAMA_SUCCESS, payload: {
        panoramaId,
        data,
    } };
}
export function panoramaError(error) {
    return { type: PANORAMA_ERROR, payload: { error } };
}


export function placeUpdateRequest(panorama, updatedPlace) {
    return { type: PLACE_UPDATE_REQUEST, payload: {
        panorama,
        updatedPlace,
    } };
}
export function placeUpdateSuccess(panorama, updatedPlace) {
    return { type: PLACE_UPDATE_SUCCESS, payload: {
        panorama,
        updatedPlace,
    } };
}
export function placeUpdateError(error) {
    return { type: PLACE_UPDATE_ERROR, payload: { error } };
}


export function placeCreateRequest(panorama, newPlace) {
    return { type: PLACE_CREATE_REQUEST, payload: {
        panorama,
        newPlace,
    } };
}
export function placeCreateSuccess(panorama, newPlace) {
    return { type: PLACE_CREATE_SUCCESS, payload: {
        panorama,
        newPlace,
    } };
}
export function placeCreateError(error) {
    return { type: PLACE_CREATE_ERROR, payload: { error } };
}


export function placeDeleteRequest(panorama, deletedPlace) {
    return { type: PLACE_DELETE_REQUEST, payload: {
        panorama,
        deletedPlace,
    } };
}
export function placeDeleteSuccess() {
    return { type: PLACE_DELETE_SUCCESS };
}
export function placeDeleteError(error) {
    return { type: PLACE_DELETE_ERROR, payload: { error } };
}


export function imageUploadRequest(panorama) {
    return { type: IMAGE_UPLOAD_REQUEST, payload: {
        panorama,
    } };
}
export function imageUploadSuccess(panorama, imageUrl) {
    return { type: IMAGE_UPLOAD_SUCCESS, payload: {
        panorama,
        imageUrl,
    } };
}
export function imageUploadError(panorama, error) {
    return { type: IMAGE_UPLOAD_ERROR, payload: {
        panorama,
        error
    } };
}


export function panoramaUpdateRequest(panoramaId, panorama) {
    return { type: PANORAMA_UPDATE_REQUEST, payload: {
        panoramaId,
        panorama,
    } };
}
export function panoramaUpdateSuccess(panoramaId, panorama) {
    return { type: PANORAMA_UPDATE_SUCCESS, payload: {
        panoramaId,
        panorama,
    } };
}
export function panoramaUpdateError(error) {
    return { type: PANORAMA_UPDATE_ERROR, payload: { error } };
}


// -----------------
// Thunks

export function fetchPanorama(panoramaId) {
    return async (dispatch, getState) => {
        dispatch(panoramaRequest(panoramaId));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest(
                "GET",
                `/secure/panoramas/${panoramaId}`,
                auth,
                env,
            );
            const responseBody = await authResponse.json();
            dispatch(
                panoramaSuccess(panoramaId, responseBody)
            );
        } catch (error) {
            console.error(error);
            dispatch(panoramaError(error));
        }
    };
}

export function updatePlace(panorama, updatedPlace) {
    return async (dispatch, getState) => {
        dispatch(placeUpdateRequest(panorama, updatedPlace));
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest(
                "PUT",
                `/secure/places/${updatedPlace.id}`,
                auth,
                env,
                updatedPlace,
            );
            dispatch(
                placeUpdateSuccess(panorama, updatedPlace)
            );
        } catch (error) {
            console.error(error);
            dispatch(placeUpdateError(error));
        }
    };
}

export function createPlace(panorama, newPlace) {
    return async (dispatch, getState) => {
        dispatch(placeCreateRequest(panorama, newPlace));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const createResponse = await makeRequest(
                "POST",
                `/secure/places`,
                auth,
                env,
                {
                    ...newPlace,
                    panorama_id: panorama.id,
                },
            );
            const newPlaceWithId = await createResponse.json();
            dispatch(
                placeCreateSuccess(panorama, newPlaceWithId)
            );
        } catch (error) {
            console.error(error);
            dispatch(placeCreateError(error));
        }
    };
}

export function deletePlace(panorama, deletedPlace) {
    return async (dispatch, getState) => {
        dispatch(placeDeleteRequest(panorama, deletedPlace));
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest(
                "DELETE",
                `/secure/places/${deletedPlace.id}`,
                auth,
                env,
            );
            dispatch(
                placeDeleteSuccess(panorama)
            );
        } catch (error) {
            console.error(error);
            dispatch(placeDeleteError(error));
        }
    };
}

export function uploadImage(panorama, file) {
    return async (dispatch, getState) => {
        dispatch(imageUploadRequest(panorama));
        const data = new FormData();
        data.append("file", file);
        try {
            const { auth, env } = selectRequestParameters(getState());
            const res = await makeRequest(
                "PUT",
                `/secure/panoramas/${panorama.id}/image`,
                auth,
                env,
                data,
            );

            const responseBody = await res.json();

            const { panorama_image_url } = responseBody;
            dispatch(imageUploadSuccess(panorama, panorama_image_url));
        } catch (error) {
            console.error(error);
            dispatch(imageUploadError(panorama, error));
        }
    };
}

export function updatePanorama(panoramaId, panorama) {
    return async (dispatch, getState) => {
        dispatch(panoramaUpdateRequest(panoramaId, panorama));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest("PATCH", `/secure/panoramas/${panoramaId}`, auth, env, panorama);
            const responseBody = await authResponse.json();
            dispatch(
                panoramaUpdateSuccess(panoramaId, responseBody)
            );
        } catch (error) {
            console.error(error);
            dispatch(panoramaUpdateError(error));
        }
    };
}

// -----------------
// Selectors
const selectPanoramaRaw = (id, state) => state.panorama.panoramas[id];
export const selectPanorama = createSelector(
    selectPanoramaRaw,
    (panorama) => panorama ? panorama.data : null,
)
export const selectPanoramaRequesting = createSelector(
    selectPanoramaRaw,
    (panorama) => {
        if (panorama && panorama.success) return false;
        return true;
    }
)
export const selectPanoramaImageUploading = createSelector(
    selectPanoramaRaw,
    (panorama) => panorama ? panorama.imageUploading : null,
)