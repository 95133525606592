import { createSelector } from "reselect";
import makeRequest from "../modules/make-request";
import { selectRequestParameters } from "./auth";
import { keyBy, set } from "lodash/fp";
const COMMENTS_REQUEST = "tfnsw-tap/comment/COMMENTS_REQUEST";
const COMMENTS_SUCCESS = "tfnsw-tap/comment/COMMENTS_SUCCESS";
const COMMENTS_ERROR = "tfnsw-tap/comment/COMMENTS_ERROR";
const UPDATE_COMMENT_STATUS_REQUEST =
    "tfnsw-tap/comment/UPDATE_COMMENT_STATUS_REQUEST";
const UPDATE_COMMENT_STATUS_SUCCESS =
    "tfnsw-tap/comment/UPDATE_COMMENT_STATUS_SUCCESS";
const UPDATE_COMMENT_STATUS_ERROR =
    "tfnsw-tap/comment/UPDATE_COMMENT_STATUS_ERROR";

const initialState = {
    commentsById: {},
    requesting: false,
    success: false,
    error: null,
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case COMMENTS_REQUEST: {
            return {
                ...state,
                requesting: true,
                success: false,
                error: null,
                commentsById: {}
            };
        }
        case UPDATE_COMMENT_STATUS_ERROR:
        case COMMENTS_ERROR: {
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.payload.error,
            };
        }
        case COMMENTS_SUCCESS: {
            return {
                ...state,
                requesting: false,
                success: true,
                error: null,
                commentsById: {
                    ...state.comments,
                    ...keyBy("id", action.payload.data),
                },
            };
        }
        case UPDATE_COMMENT_STATUS_REQUEST: {
            const {
                payload: { id, status },
            } = action;
            return set(["commentsById", id, "status"], status, state);
        }
        case UPDATE_COMMENT_STATUS_SUCCESS: {
            const {
                payload: { id, data },
            } = action;
            const newState = {
                ...state.commentsById[id],
                ...data,
            }
            return set(["commentsById", id], newState, state);
        }
        default:
            return state;
    }
}

// -----------------
// Action creators

export function commentsRequest() {
    return { type: COMMENTS_REQUEST };
}
export function commentsSuccess(data) {
    return { type: COMMENTS_SUCCESS, payload: { data } };
}
export function commentsError(error) {
    return { type: COMMENTS_ERROR, payload: { error } };
}
export function updateCommentStatusRequest(id, status) {
    return { type: UPDATE_COMMENT_STATUS_REQUEST, payload: { id, status } };
}
export function updateCommentStatusSuccess(id, data) {
    return { type: UPDATE_COMMENT_STATUS_SUCCESS, payload: { id, data } };
}
export function updateCommentStatusError(error) {
    return { type: UPDATE_COMMENT_STATUS_ERROR, payload: { error } };
}

// -----------------
// Thunks

export function updateCommentStatus(id, status) {
    return async (dispatch, getState) => {
        dispatch(updateCommentStatusRequest(id, status));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest(
                "PUT",
                `/secure/comments/${id}`,
                auth,
                env,
                {
                    status,
                }
            );
            const responseBody = await authResponse.json();
            dispatch(updateCommentStatusSuccess(id, responseBody));
        } catch (error) {
            console.error(error);
            dispatch(updateCommentStatusError(error));
        }
    };
}
export function fetchComments(query = "") {
    return async (dispatch, getState) => {
        dispatch(commentsRequest());
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest(
                "GET",
                `/secure/comments/?${query}`,
                auth,
                env,
            );
            const responseBody = await authResponse.json();
            dispatch(commentsSuccess(responseBody));
        } catch (error) {
            console.error(error);
            dispatch(commentsError(error));
        }
    };
}

// -----------------
// Selectors
export const selectComments = createSelector(
    ({ comment }) => Object.values(comment.commentsById),
    x => x
);
