import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import EditableHeader from "../../components/editable-header";
import Loader from "../../components/loader";
import Ripple from "../../components/loader/ripple";
import { ReactComponent as IconCross } from "../../components/icons/cross.svg";
import {
    fetchUpgrade,
    selectCurrentUpgradeRequesting,
    selectCurrentUpgrade,
    selectCurrentUpgradeId,
    createNewComponent,
    createNewPanorama,
    updateComponent,
    deleteComponent,
    uploadStationMap,
    selectStationMapUploading,
    updateUpgrade,
} from "../../ducks/upgrade";
import { getUrl } from "../../modules/make-request";
import getStationMapUrl from "../../modules/get-station-map-url";

import styles from "./upgrade.module.scss";
import { selectEnv, selectAuth } from "../../ducks/auth";

const getMapFromForm = formEl => {
    const fd = new FormData(formEl);
    let ret = {};
    fd.forEach((value, key) => {
        ret[key] = value;
    });
    return ret;
};

const getImageXYFromEvent = e => {
    const rect = e.target.getBoundingClientRect();
    const xPx = e.clientX - rect.left;
    const yPx = e.clientY - rect.top;
    const x = xPx / rect.width;
    const y = yPx / rect.height;

    return { x, y };
};

class UpgradePage extends Component {
    constructor() {
        super();
        this.state = {
            mapHash: null,
            mapHovered: null,
            mapDragging: null,
            dragX: null,
            dragY: null,
            enabled: {},
        };
    }

    componentDidMount() {
        const { fetchUpgrade, upgradeId } = this.props;
        fetchUpgrade(upgradeId);
    }

    renderExportButton() {
        const { env, upgradeId, token } = this.props;
        return (
            <a
                className={"button " + styles.downloadButton}
                target="_blank"
                rel="noopener noreferrer"
                href={getUrl(`/comments/export?upgradeId=${upgradeId}&key=${token}`, env)}
            >
                Download all comments as .csv file
            </a>
        );
    }

    // -----------

    handleNewImageUpload = async e => {
        const { uploadStationMap, upgrade } = this.props;

        e.preventDefault();

        if (!e.currentTarget.value) {
            return;
        }

        const file = this.uploadInput.files[0];
        const mapHash = Date.now();
        await uploadStationMap(upgrade, file);

        // TODO: possibly refactor imagehash into redux?
        this.setState({ mapHash });
    };

    renderNewImageButton() {
        const { stationMapUploading } = this.props;
        const uploadIndicator = stationMapUploading && (
            <div className={styles.imageFormUploadingOverlay}>
                <Ripple />
            </div>
        );
        return (
            <form className={styles.imageForm}>
                <label className={styles.imageFormUploadButton} htmlFor="imageFileInput">
                    Upload a new station map
                </label>
                <input
                    ref={r => (this.uploadInput = r)}
                    type="file"
                    id="imageFileInput"
                    onChange={this.handleNewImageUpload}
                    accept="image/*"
                    className={styles.imageFormFileField}
                />
                {uploadIndicator}
            </form>
        );
    }

    getMarkerHoverHandler = component => {
        return () => {
            this.setState({
                mapHovered: component || null,
                dragX: (component && component.station_map_x) || 0.5,
                dragY: (component && component.station_map_y) || 0.5,
            });
        };
    };

    getMarkerMouseDownHandler = component => {
        return () => {
            this.setState({
                mapDragging: component || null,
            });
        };
    };

    getMarkerMouseUpHandler = () => {
        return () => {
            const { updateComponent, upgradeId } = this.props;
            const { dragX, dragY, mapDragging } = this.state;
            if (mapDragging) {
                updateComponent(upgradeId, mapDragging.id, {
                    station_map_x: dragX,
                    station_map_y: dragY,
                });
            }
            this.setState({
                mapDragging: null,
                dragX: null,
                dragY: null,
            });
        };
    };

    handleMapMouseMove = e => {
        if (this.state.mapDragging) {
            const { x, y } = getImageXYFromEvent(e);
            this.setState({
                dragX: x,
                dragY: y,
            });
        }
    };

    renderStationMap(stationMapUrl) {
        const { upgrade } = this.props;
        const { mapHovered, mapDragging, dragX, dragY } = this.state;

        const markers = (upgrade.components || []).map((component, index) => {
            const isHovered = mapHovered && mapHovered.id === component.id;
            const isDragging = mapDragging && mapDragging.id === component.id;
            const isUnitialized = !component.station_map_x;

            const x = isDragging ? dragX : component.station_map_x || 0.5;
            const y = isDragging ? dragY : component.station_map_y || 0.5;

            return (
                <div
                    key={component.id}
                    className={styles.stationMapMarker}
                    style={{
                        left: x * 100 + "%",
                        top: y * 100 + "%",
                    }}
                    onMouseEnter={this.getMarkerHoverHandler(component)}
                    onMouseLeave={this.getMarkerHoverHandler(null)}
                    onMouseDown={this.getMarkerMouseDownHandler(component)}
                    onMouseUp={this.getMarkerMouseUpHandler()}
                    data-hovered={isHovered}
                    data-dragging={isDragging}
                    data-uninitialized={isUnitialized}
                >
                    {index + 1}
                </div>
            );
        });

        return (
            <div className={styles.imageContainer}>
                <img
                    src={stationMapUrl}
                    alt=""
                    onMouseMove={this.handleMapMouseMove}
                    onMouseUp={this.getMarkerMouseUpHandler()}
                    draggable={false}
                />
                {markers}
            </div>
        );
    }

    renderImageContent() {
        const { env, upgrade } = this.props;
        const stationMapUrl = getStationMapUrl(upgrade, env, this.state.mapHash);
        const imageContent = stationMapUrl ? (
            this.renderStationMap(stationMapUrl)
        ) : (
            <p>Please upload a station map</p>
        );

        return (
            <div>
                <p className={styles.imageInstructions}>
                    Click and drag to move the markers on the map, and click again to release.
                </p>
                {imageContent}
                {this.renderNewImageButton()}
            </div>
        );
    }

    // -----------

    getNewPanoHandler = component => {
        const { createNewPanorama, upgradeId } = this.props;
        return e => {
            e.preventDefault();
            createNewPanorama(upgradeId, component.id, getMapFromForm(e.currentTarget));
            this.setState({
                enabled: {
                    ...this.state.enabled,
                    [component.id]: false,
                },
            });
        };
    };

    getComponentRenameHandler = component => {
        const { updateComponent, upgradeId } = this.props;
        return newName => {
            updateComponent(upgradeId, component.id, {
                type: newName,
            });
        };
    };

    getComponentDeleteHandler = component => {
        const { upgradeId, deleteComponent } = this.props;
        return () => {
            if (window.confirm(`Are you sure you want to delete component "${component.type}"?`)) {
                deleteComponent(upgradeId, component.id);
            }
        };
    };

    renderComponentList() {
        const { upgrade } = this.props;
        const { components } = upgrade;

        const items = components.map((component, componentIndex) => {
            const panoListItems =
                component.panoramas && component.panoramas.length ? (
                    component.panoramas.map((panorama, panoramaIndex) => {
                        return (
                            <Link
                                to={`/panoramas/${panorama.id}`}
                                key={panorama.id}
                                className={styles.panoLink}
                            >
                                <span>{panorama.name}</span>
                            </Link>
                        );
                    })
                ) : (
                    <p>No panoramas yet!</p>
                );

            const newPanoHandler = this.getNewPanoHandler(component);
            const renameHandler = this.getComponentRenameHandler(component);
            const deleteHandler = this.getComponentDeleteHandler(component);

            const submitDisabled = !Boolean(this.state.enabled[component.id]);

            return (
                <li key={component.id} className={styles.componentCard}>
                    <div className={styles.componentHeader}>
                        <div className={styles.componentRank}>{componentIndex + 1}</div>
                        <EditableHeader onChange={renameHandler} value={component.type} />
                        <button className={styles.deleteComponentButton} onClick={deleteHandler}>
                            <span>Delete</span> <IconCross />
                        </button>
                    </div>
                    <div className={styles.componentContent}>
                        <h4>Panoramas</h4>
                        <div>{panoListItems}</div>
                        <form className={styles.newPanoForm} onSubmit={newPanoHandler}>
                            <input
                                type="text"
                                className="small"
                                placeholder="Panorama name"
                                name="name"
                                onChange={e => {
                                    this.setState({
                                        enabled: {
                                            ...this.state.enabled,
                                            [component.id]: e.currentTarget.value.length > 0,
                                        },
                                    });
                                }}
                            />
                            <button
                                className="light small button"
                                type="submit"
                                disabled={submitDisabled}
                            >
                                + Add a new panorama
                            </button>
                        </form>
                    </div>
                </li>
            );
        });
        return <ul className={styles.componentList}>{items}</ul>;
    }

    handleNewComponent = e => {
        const { createNewComponent, upgradeId } = this.props;
        e.preventDefault();
        createNewComponent(upgradeId, getMapFromForm(e.currentTarget));
    };

    renderNewComponentButton() {
        return (
            <form className={styles.newComponentForm} onSubmit={this.handleNewComponent}>
                <input type="text" className="small" placeholder="Component name" name="type" />
                <button className="light small button" type="submit">
                    + Add a new component
                </button>
            </form>
        );
    }
    renderOpenForCommentBtn() {
        const { upgrade, updateUpgrade } = this.props;
        const openForComment = upgrade.open_for_comment;
        return (
            <div>
                <p>Comments and upvotes are {openForComment ? "enabled" : "disabled"}.</p>
                <button
                    className={"button " + styles.toogleCommentButton}
                    onClick={e => {
                        updateUpgrade(upgrade.id, { open_for_comment: !openForComment });
                    }}
                >
                    {openForComment ? "Disable" : "Enable"} comments
                </button>
            </div>
        );
    }

    render() {
        const { requesting } = this.props;
        if (requesting) {
            return <Loader />;
        }
        return (
            <div>
                {this.renderExportButton()}
                {this.renderOpenForCommentBtn()}
                {this.renderImageContent()}
                {this.renderComponentList()}
                {this.renderNewComponentButton()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        upgradeId: selectCurrentUpgradeId(state),
        upgrade: selectCurrentUpgrade(state),
        requesting: selectCurrentUpgradeRequesting(state),
        stationMapUploading: selectStationMapUploading(state),
        env: selectEnv(state),
        token: selectAuth(state),
    };
};
const mapDispatchToProps = {
    fetchUpgrade,
    createNewComponent,
    createNewPanorama,
    updateComponent,
    deleteComponent,
    uploadStationMap,
    updateUpgrade,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpgradePage);
