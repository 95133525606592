import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalWrapper from "../../components/modal-wrapper";
import PlaceModal from "../../components/place-modal";
import Loader from "../../components/loader";
import Ripple from "../../components/loader/ripple";
import EditableHeader from "../../components/editable-header";
import layout from "../../style/layout.module.scss";
import {
    fetchPanorama,
    updatePlace,
    createPlace,
    deletePlace,
    uploadImage,
    selectPanoramaRequesting,
    selectPanorama,
    selectPanoramaImageUploading,
    updatePanorama,
} from "../../ducks/panorama";
import getPanoramaImageUrl from "../../modules/get-panorama-image-url";

import styles from "./panorama.module.scss";
import Tabs from "../../components/tabs";
import CommentTable from "../../components/comment-table";
import { selectEnv } from "../../ducks/auth";

class PanoramaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            editingPlace: null,
            hoveredPlace: null,
            imageHash: null,
        };
    }

    componentDidMount() {
        const { fetchPanorama, panoramaId } = this.props;
        fetchPanorama(panoramaId);
    }

    renderImageMarkers() {
        const { places } = this.props.panorama;
        const { hoveredPlace } = this.state;

        return places
            .filter(place => place.center_x !== null && place.center_y !== null)
            .map(place => {
                const isHovered = hoveredPlace && hoveredPlace.id === place.id;
                const x = place.center_x;
                const y = place.center_y;
                return (
                    <div
                        className={styles.panoramaImageMarker}
                        data-hovered={isHovered}
                        key={place.id || place.name}
                        style={{
                            left: x * 100 + "%",
                            top: y * 100 + "%",
                        }}
                        onClick={this.getEditHandler(place)}
                        onMouseEnter={() =>
                            this.setState({ hoveredPlace: place })
                        }
                        onMouseLeave={() =>
                            this.setState({ hoveredPlace: null })
                        }
                    />
                );
            });
    }

    renderImage() {
        const { panorama, env } = this.props;
        const { name } = panorama;
        const { imageHash } = this.state;

        const panoramaImageUrl = getPanoramaImageUrl(panorama, env, imageHash);

        let imageContent;
        let markerContent;
        if (panoramaImageUrl) {
            imageContent = (
                <img
                    alt={name}
                    className={styles.panoramaImage}
                    src={panoramaImageUrl}
                />
            );

            markerContent = this.renderImageMarkers();
        } else {
            imageContent = (
                <p className={styles.panoramaPlaceholder}>
                    Please upload an image before proceeding.
                </p>
            );
        }

        return (
            <div className={styles.panoramaImageContainer}>
                {imageContent}
                {markerContent}
            </div>
        );
    }

    handleNewImageUpload = async e => {
        e.preventDefault();

        if (!e.currentTarget.value) {
            return;
        }

        const { uploadImage, panorama } = this.props;
        const file = this.uploadInput.files[0];
        const imageHash = Date.now();
        await uploadImage(panorama, file);

        // TODO: possibly refactor imagehash into redux?
        this.setState({ imageHash });
    };

    renderNewImageButton() {
        const { imageUploading } = this.props;
        const uploadIndicator = imageUploading && (
            <div className={styles.imageFormUploadingOverlay}>
                <Ripple />
            </div>
        );
        return (
            <form className={styles.imageForm}>
                <label
                    className={styles.imageFormUploadButton}
                    htmlFor="imageFileInput"
                >
                    Upload a new image
                </label>
                <input
                    ref={r => (this.uploadInput = r)}
                    type="file"
                    id="imageFileInput"
                    onChange={this.handleNewImageUpload}
                    accept="image/*"
                    className={styles.imageFormFileField}
                />
                {uploadIndicator}
            </form>
        );
    }

    renderPlaceHeading() {
        return (
            <div className={styles.placeHeading}>
                <h3>Places</h3>
            </div>
        );
    }

    getEditHandler = place => e => {
        e.preventDefault();
        this.setState({
            modalOpen: true,
            editingPlace: place,
        });
    };

    getDeleteHandler = place => e => {
        e.stopPropagation();
        const { panorama, deletePlace } = this.props;
        if (
            window.confirm(`Are you sure you want to delete "${place.name}"?`)
        ) {
            deletePlace(panorama, place);
        }
    };

    renderPlaceTable() {
        const { places } = this.props.panorama;
        const { hoveredPlace } = this.state;

        const body = places.length ? (
            places.map(place => {
                const editHandler = this.getEditHandler(place);
                const deleteHandler = this.getDeleteHandler(place);

                const isHovered = hoveredPlace && hoveredPlace.id === place.id;

                return (
                    <tr
                        key={place.id || place.name}
                        data-hovered={isHovered}
                        onMouseEnter={() =>
                            this.setState({ hoveredPlace: place })
                        }
                        onMouseLeave={() =>
                            this.setState({ hoveredPlace: null })
                        }
                        onClick={editHandler}
                    >
                        <td>{place.name}</td>
                        <td className={styles.placeTableButtonCell}>
                            <button
                                className="light small"
                                onClick={editHandler}
                            >
                                Edit
                            </button>
                            <button
                                className="danger small"
                                onClick={deleteHandler}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td>
                    <p>
                        No places defined. Click the '+' above to get started.
                    </p>
                </td>
            </tr>
        );

        return (
            <table className={styles.placeTable}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{body}</tbody>
            </table>
        );
    }

    handleAddClick = () => {
        this.setState({ modalOpen: !this.state.modalOpen, editingPlace: null });
    };

    renderAddButton() {
        return (
            <button
                className="light small button"
                onClick={this.handleAddClick}
            >
                + Add a new place
            </button>
        );
    }

    handlePlaceEdited = editedPlace => {
        const { panorama, updatePlace } = this.props;
        updatePlace(panorama, editedPlace);

        this.setState({
            modalOpen: false,
            editingPlace: null,
        });
    };

    handlePlaceCreated = newPlace => {
        const { panorama, createPlace } = this.props;
        createPlace(panorama, newPlace);

        this.setState({
            modalOpen: false,
            editingPlace: null,
        });
    };

    handlePlaceDeleted = deletedPlace => {
        const { panorama, deletePlace } = this.props;
        deletePlace(panorama, deletedPlace);
        this.setState({
            modalOpen: false,
            editingPlace: null,
        });
    };

    handleModalCloseClick = () => {
        this.setState({ modalOpen: false, editingPlace: null });
    };

    renderEditModal() {
        const { panorama, env } = this.props;
        const { modalOpen, editingPlace, imageHash } = this.state;
        return (
            <ModalWrapper isVisible={modalOpen}>
                <PlaceModal
                    panorama={panorama}
                    editingPlace={editingPlace}
                    imageHash={imageHash}
                    onCloseClick={this.handleModalCloseClick}
                    onPlaceEdited={this.handlePlaceEdited}
                    onPlaceCreated={this.handlePlaceCreated}
                    onPlaceDeleted={this.handlePlaceDeleted}
                    env={env}
                />
            </ModalWrapper>
        );
    }

    renderContentRequiringImage() {
        const { env } = this.props;
        const panoramaImageUrl = getPanoramaImageUrl(this.props.panorama, env);
        if (!panoramaImageUrl) return null;
        return (
            <>
                <div className={layout.row}>
                    {this.renderPlaceHeading()}
                    {this.renderPlaceTable()}
                </div>
                <div className={layout.row}>{this.renderAddButton()}</div>
                {this.renderEditModal()}
            </>
        );
    }
    renderPanorama() {
        return (
            <>
                <div className={layout.row}>{this.renderImage()}</div>
                <div className={layout.row}>{this.renderNewImageButton()}</div>
                {this.renderContentRequiringImage()}
            </>
        );
    }

    handlePanoramaRename = (newName) => {
        const { updatePanorama, panoramaId } = this.props;
        updatePanorama(panoramaId, {
            name: newName,
        })
    }

    render() {
        const { requesting, panorama } = this.props;
        if (requesting) {
            return <Loader />;
        }
        return (
            <div>
                <Link
                    className={layout.backLink}
                    to="/"
                >
                    Back to TAP admin
                </Link>
                <EditableHeader onChange={this.handlePanoramaRename} value={panorama.name} />
                <Tabs tabs={["Panorama", "Comments"]} initalTab={"Panorama"}>
                    {activeTab =>
                        activeTab === "Panorama" ? (
                            this.renderPanorama()
                        ) : (
                            <CommentTable panorama={panorama}/>
                        )
                    }
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const panoramaId = props.match.params.panoramaId;
    return {
        panoramaId,
        panorama: selectPanorama(panoramaId, state),
        requesting: selectPanoramaRequesting(panoramaId, state),
        imageUploading: selectPanoramaImageUploading(panoramaId, state),
        env: selectEnv(state),
    };
};
const mapDispatchToProps = {
    fetchPanorama,
    updatePlace,
    createPlace,
    deletePlace,
    uploadImage,
    updatePanorama,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoramaPage);
