import React from "react";
import PropTypes from "prop-types";
import styles from "./tabs.module.scss";
import Tab from "./tab";

const propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    initalTab: PropTypes.string,
    children: PropTypes.func.isRequired,
};

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.initalTab || props.optons[0],
        };
    }
    render() {
        const { tabs } = this.props;
        return (
            <>
                <ul className={styles.list}>
                    {tabs.map(tab => (
                        <Tab
                            key={tab}
                            onChange={tab => this.setState({ activeTab: tab })}
                            tab={tab}
                            active={tab === this.state.activeTab}
                        />
                    ))}
                </ul>
                {this.props.children(this.state.activeTab)}
            </>
        );
    }
}

Tabs.propTypes = propTypes;

export default Tabs;
