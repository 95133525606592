import { createSelector } from "reselect";
import  makeRequest from "../modules/make-request";
import { selectRequestParameters } from "./auth";

const COMPONENT_REQUEST = "tfnsw-tap/component/COMPONENT_REQUEST";
const COMPONENT_SUCCESS = "tfnsw-tap/component/COMPONENT_SUCCESS";
const COMPONENT_ERROR = "tfnsw-tap/component/COMPONENT_ERROR";

const initialState = {
    components: { },
};

// TODO: REMOVE THIS ENTIRELY?

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case COMPONENT_REQUEST: {
            return {
                ...state,
                components: {
                    ...state.components,
                    [action.payload.componentId]: {
                        requesting: true,
                        success: false,
                        error: null,
                    }
                }
            };
        }
        case COMPONENT_ERROR: {
            return {
                ...state,
                components: {
                    ...state.components,
                    [action.payload.componentId]: {
                        requesting: false,
                        success: false,
                        error: action.payload.error,
                    }
                },
            };
        }
        case COMPONENT_SUCCESS: {
            return {
                ...state,
                components: {
                    ...state.components,
                    [action.payload.componentId]: {
                        requesting: false,
                        success: true,
                        error: null,
                        data: action.payload.data,
                    }
                },
            };
        }
        default:
            return state;
    }
}

// -----------------
// Action creators

export function componentRequest(componentId) {
    return { type: COMPONENT_REQUEST, payload: {
        componentId,
    } };
}
export function componentSuccess(componentId, data) {
    return { type: COMPONENT_SUCCESS, payload: {
        componentId,
        data,
    } };
}
export function componentError(error) {
    return { type: COMPONENT_ERROR, payload: { error } };
}

// -----------------
// Thunks

export function fetchComponent(componentId) {
    return async (dispatch, getState) => {
        dispatch(componentRequest(componentId));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest(
                "GET",
                `/secure/components/${componentId}`,
                auth,
                env
            );
            const responseBody = await authResponse.json();
            dispatch(
                componentSuccess(componentId, responseBody)
            );
        } catch (error) {
            console.error(error);
            dispatch(componentError(error));
        }
    };
}

// -----------------
// Selectors
const selectComponentRaw = (id, state) => state.component.components[id];
export const selectComponent = createSelector(
    selectComponentRaw,
    (component) => component ? component.data : null,
)
export const selectComponentRequesting = createSelector(
    selectComponentRaw,
    (component) => {
        if (component && component.success) return false;
        return true;
    }
)