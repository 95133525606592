import React from "react";
import styles from "./tab.module.scss";
function Tab({ tab, active, onChange }) {
    return (
        <div onClick={() => onChange(tab)} className={styles.tabItem} key={tab}>
            <button className={styles.button} data-active={active}>
                {tab}
            </button>
        </div>
    );
}

export default Tab;
