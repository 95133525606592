import { combineReducers } from "redux";

import upgrade from "./upgrade";
import component from "./component";
import panorama from "./panorama";
import comment from "./comment";
import auth from "./auth";

export default combineReducers({
    upgrade,
    component,
    panorama,
    comment,
    auth,
});
