import React from "react";
import styles from "./loader.module.scss";
import Ripple from "./ripple";

const Loader = ({ children }) => {
    return (
        <div className={styles.loader}>
            <Ripple />
            {children ? children : null}
        </div>
    );
};

export default Loader;
