import { createSelector } from "reselect";

const initialState = {
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        default:
            return state;
    }
}

// -----------------
// Action creators

// Not required - in production environments this is embedded and in initial state.

// -----------------
// Selectors
export const selectAuth = state => state.auth.token;
export const selectEnv = state => state.auth.env;

export const selectRequestParameters = createSelector(
    selectAuth,
    selectEnv,
    (auth, env) => ({ auth, env })
);