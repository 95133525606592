import { createSelector } from "reselect";
import makeRequest from "../modules/make-request";
import { selectRequestParameters } from "./auth";

const UPGRADE_REQUEST = "tfnsw-tap/upgrade/UPGRADE_REQUEST";
const UPGRADE_SUCCESS = "tfnsw-tap/upgrade/UPGRADE_SUCCESS";
const UPGRADE_ERROR = "tfnsw-tap/upgrade/UPGRADE_ERROR";

const UPGRADE_PUT_REQUEST = "tfnsw-tap/upgrade/UPGRADE_PUT_REQUEST";
const UPGRADE_PUT_SUCCESS = "tfnsw-tap/upgrade/UPGRADE_SUCCESS";
const UPGRADE_PUT_ERROR = "tfnsw-tap/upgrade/UPGRADE_PUT_ERROR";

const NEW_COMPONENT_REQUEST = "tfnsw-tap/upgrade/NEW_COMPONENT_REQUEST";
const NEW_COMPONENT_SUCCESS = "tfnsw-tap/upgrade/NEW_COMPONENT_SUCCESS";
const NEW_COMPONENT_ERROR = "tfnsw-tap/upgrade/NEW_COMPONENT_ERROR";

const UPDATE_COMPONENT_REQUEST = "tfnsw-tap/upgrade/UPDATE_COMPONENT_REQUEST";
const UPDATE_COMPONENT_SUCCESS = "tfnsw-tap/upgrade/UPDATE_COMPONENT_SUCCESS";
const UPDATE_COMPONENT_ERROR = "tfnsw-tap/upgrade/UPDATE_COMPONENT_ERROR";

const NEW_PANORAMA_REQUEST = "tfnsw-tap/upgrade/NEW_PANORAMA_REQUEST";
const NEW_PANORAMA_SUCCESS = "tfnsw-tap/upgrade/NEW_PANORAMA_SUCCESS";
const NEW_PANORAMA_ERROR = "tfnsw-tap/upgrade/NEW_PANORAMA_ERROR";

const DELETE_COMPONENT_REQUEST = "tfnsw-tap/upgrade/DELETE_COMPONENT_REQUEST";
const DELETE_COMPONENT_SUCCESS = "tfnsw-tap/upgrade/DELETE_COMPONENT_SUCCESS";
const DELETE_COMPONENT_ERROR = "tfnsw-tap/upgrade/DELETE_COMPONENT_ERROR";

const STATION_MAP_UPLOAD_REQUEST = "tfnsw-tap/upgrade/STATION_MAP_UPLOAD_REQUEST";
const STATION_MAP_UPLOAD_SUCCESS = "tfnsw-tap/upgrade/STATION_MAP_UPLOAD_SUCCESS";
const STATION_MAP_UPLOAD_ERROR = "tfnsw-tap/upgrade/STATION_MAP_UPLOAD_ERROR";

const initialState = {
    upgrades: {},
    currentUpgrade: null,
    stationMap: {},
};

// -----------------
// Reducer

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case UPGRADE_REQUEST: {
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        requesting: true,
                        success: false,
                        error: null,
                    },
                },
            };
        }
        case UPGRADE_SUCCESS: {
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        requesting: false,
                        success: true,
                        error: null,
                        data: action.payload.data,
                    },
                },
            };
        }
        case UPGRADE_PUT_REQUEST: {
            const { upgradeId, data } = action.payload;
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [upgradeId]: {
                        ...state.upgrades[upgradeId],
                        data: {
                            ...state.upgrades[upgradeId].data,
                            open_for_comment: data.open_for_comment,
                        },
                    },
                },
            };
        }
        case UPGRADE_PUT_SUCCESS: {
            // Optimistically updated
            return state;
        }

        case NEW_COMPONENT_REQUEST: {
            const { upgradeId } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        ...originalUpgrade,
                        requesting: true,
                        success: false,
                        error: null,
                    },
                },
            };
        }

        case NEW_COMPONENT_SUCCESS: {
            const { upgradeId, component } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [upgradeId]: {
                        requesting: false,
                        success: true,
                        error: null,
                        data: {
                            ...originalUpgrade.data,
                            components: [...originalUpgrade.data.components, component],
                        },
                    },
                },
            };
        }

        case NEW_PANORAMA_REQUEST: {
            const { upgradeId } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        ...originalUpgrade,
                        requesting: true,
                        success: false,
                        error: null,
                    },
                },
            };
        }
        case NEW_PANORAMA_SUCCESS: {
            const { upgradeId, componentId, panorama } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            const newComponents = originalUpgrade.data.components.map(component => {
                if (component.id === componentId) {
                    return {
                        ...component,
                        panoramas: (component.panoramas || []).concat([panorama]),
                    };
                }
                return component;
            });
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [upgradeId]: {
                        requesting: false,
                        success: true,
                        error: null,
                        data: {
                            ...originalUpgrade.data,
                            components: newComponents,
                        },
                    },
                },
            };
        }

        case UPDATE_COMPONENT_REQUEST: {
            const { upgradeId, componentId, component } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            const newComponents = originalUpgrade.data.components.map(originalComponent => {
                if (originalComponent.id === componentId) {
                    // Update with our new component
                    return {
                        ...originalComponent,
                        ...component,
                    };
                }
                return originalComponent;
            });
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        ...originalUpgrade,
                        data: {
                            ...originalUpgrade.data,
                            components: newComponents,
                        },
                    },
                },
            };
        }

        case UPDATE_COMPONENT_SUCCESS: {
            // Update optimistically.
            // Server doesn't currently return anything useful anyway.
            return state;
        }

        case DELETE_COMPONENT_REQUEST: {
            const { upgradeId, componentId } = action.payload;
            const originalUpgrade = state.upgrades[upgradeId];
            const newComponents = originalUpgrade.data.components.filter(
                originalComponent => originalComponent.id !== componentId
            );
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        ...originalUpgrade,
                        data: {
                            ...originalUpgrade.data,
                            components: newComponents,
                        },
                    },
                },
            };
        }

        case DELETE_COMPONENT_SUCCESS: {
            // Update optimistically.
            // Server doesn't currently return anything useful anyway.
            return state;
        }

        case STATION_MAP_UPLOAD_REQUEST: {
            return {
                ...state,
                stationMap: {
                    uploading: true,
                    success: false,
                    error: null,
                },
            };
        }
        case STATION_MAP_UPLOAD_ERROR: {
            const { error } = action.payload;
            return {
                ...state,
                stationMap: {
                    uploading: true,
                    success: false,
                    error: error,
                },
            };
        }
        case STATION_MAP_UPLOAD_SUCCESS: {
            return {
                ...state,
                stationMap: {
                    uploading: false,
                    success: true,
                    error: null,
                },
            };
        }
        case UPGRADE_PUT_ERROR:
        case UPGRADE_ERROR:
        case NEW_COMPONENT_ERROR:
        case NEW_PANORAMA_ERROR:
        case UPDATE_COMPONENT_ERROR:
        case DELETE_COMPONENT_ERROR: {
            return {
                ...state,
                upgrades: {
                    ...state.upgrades,
                    [action.payload.upgradeId]: {
                        requesting: false,
                        success: false,
                        error: action.payload.error,
                    },
                },
            };
        }

        default:
            return state;
    }
}

// -----------------
// Action creators

export function upgradeRequest(upgradeId) {
    return {
        type: UPGRADE_REQUEST,
        payload: {
            upgradeId,
        },
    };
}
export function upgradeSuccess(upgradeId, data) {
    return {
        type: UPGRADE_SUCCESS,
        payload: {
            upgradeId,
            data,
        },
    };
}
export function upgradeError(upgradeId, error) {
    return { type: UPGRADE_ERROR, payload: { upgradeId, error } };
}
function upgradePutRequest(upgradeId, data) {
    return {
        type: UPGRADE_PUT_REQUEST,
        payload: {
            upgradeId,
            data,
        },
    };
}

function upgradePutError(upgradeId, error) {
    return { type: UPGRADE_PUT_ERROR, payload: { upgradeId, error } };
}

export function newComponentRequest(upgradeId, component) {
    return {
        type: NEW_COMPONENT_REQUEST,
        payload: {
            upgradeId,
            component,
        },
    };
}
export function newComponentSuccess(upgradeId, component) {
    return {
        type: NEW_COMPONENT_SUCCESS,
        payload: {
            upgradeId,
            component,
        },
    };
}
export function newComponentError(upgradeId, error) {
    return { type: NEW_COMPONENT_ERROR, payload: { upgradeId, error } };
}

export function newPanoramaRequest(upgradeId, componentId, panorama) {
    return {
        type: NEW_PANORAMA_REQUEST,
        payload: {
            upgradeId,
            componentId,
            panorama,
        },
    };
}
export function newPanoramaSuccess(upgradeId, componentId, panorama) {
    return {
        type: NEW_PANORAMA_SUCCESS,
        payload: {
            upgradeId,
            componentId,
            panorama,
        },
    };
}
export function newPanoramaError(upgradeId, error) {
    return { type: NEW_PANORAMA_ERROR, payload: { upgradeId, error } };
}

export function updateComponentRequest(upgradeId, componentId, component) {
    return {
        type: UPDATE_COMPONENT_REQUEST,
        payload: {
            upgradeId,
            component,
            componentId,
        },
    };
}
export function updateComponentSuccess(upgradeId, componentId) {
    return {
        type: UPDATE_COMPONENT_SUCCESS,
        payload: {
            upgradeId,
            componentId,
        },
    };
}
export function updateComponentError(error) {
    return { type: UPDATE_COMPONENT_ERROR, payload: { error } };
}

export function deleteComponentRequest(upgradeId, componentId) {
    return {
        type: DELETE_COMPONENT_REQUEST,
        payload: {
            upgradeId,
            componentId,
        },
    };
}
export function deleteComponentSuccess(upgradeId, componentId) {
    return {
        type: DELETE_COMPONENT_SUCCESS,
        payload: {
            upgradeId,
            componentId,
        },
    };
}
export function deleteComponentError(error) {
    return { type: DELETE_COMPONENT_ERROR, payload: { error } };
}

export function imageUploadRequest() {
    return {
        type: STATION_MAP_UPLOAD_REQUEST,
        payload: {},
    };
}
export function imageUploadSuccess() {
    return {
        type: STATION_MAP_UPLOAD_SUCCESS,
        payload: {},
    };
}
export function imageUploadError(error) {
    return {
        type: STATION_MAP_UPLOAD_ERROR,
        payload: {
            error,
        },
    };
}

// -----------------
// Thunks

export function fetchUpgrade(upgradeId) {
    return async (dispatch, getState) => {
        dispatch(upgradeRequest(upgradeId));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest(
                "GET",
                `/secure/upgrades/${upgradeId}`,
                auth,
                env
            );
            const responseBody = await authResponse.json();
            dispatch(upgradeSuccess(upgradeId, responseBody));
        } catch (error) {
            console.error(error);
            dispatch(upgradeError(upgradeId, error.toString()));
        }
    };
}

export function updateUpgrade(upgradeId, data) {
    return async (dispatch, getState) => {
        dispatch(upgradePutRequest(upgradeId, data));
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest("PUT", `/secure/upgrades/${upgradeId}`, auth, env, data);
        } catch (error) {
            console.error(error);
            dispatch(upgradePutError(upgradeId, error.toString()));
        }
    };
}
export function createNewComponent(upgradeId, component) {
    return async (dispatch, getState) => {
        dispatch(newComponentRequest(upgradeId, component));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest("POST", `/secure/components/`, auth, env, {
                ...component,
                upgrade_id: upgradeId,
            });
            const responseBody = await authResponse.json();
            dispatch(newComponentSuccess(upgradeId, responseBody));
        } catch (error) {
            console.error(error);
            dispatch(newComponentError(upgradeId, error.toString()));
        }
    };
}

export function createNewPanorama(upgradeId, componentId, panorama) {
    return async (dispatch, getState) => {
        dispatch(newPanoramaRequest(upgradeId, componentId, panorama));
        try {
            const { auth, env } = selectRequestParameters(getState());
            const authResponse = await makeRequest("POST", `/secure/panoramas/`, auth, env, {
                ...panorama,
                component_id: componentId,
            });
            const responseBody = await authResponse.json();
            dispatch(newPanoramaSuccess(upgradeId, componentId, responseBody));
        } catch (error) {
            console.error(error);
            dispatch(newPanoramaError(upgradeId, error.toString()));
        }
    };
}

export function updateComponent(upgradeId, componentId, component) {
    return async (dispatch, getState) => {
        dispatch(updateComponentRequest(upgradeId, componentId, component));
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest("PATCH", `/secure/components/${componentId}`, auth, env, component);
            dispatch(updateComponentSuccess(upgradeId, componentId));
        } catch (error) {
            console.error(error);
            dispatch(updateComponentError(error));
        }
    };
}

export function deleteComponent(upgradeId, componentId) {
    return async (dispatch, getState) => {
        dispatch(deleteComponentRequest(upgradeId, componentId));
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest("DELETE", `/secure/components/${componentId}`, auth, env);
            dispatch(deleteComponentSuccess(upgradeId, componentId));
        } catch (error) {
            console.error(error);
            dispatch(deleteComponentError(error));
        }
    };
}

export function uploadStationMap(upgrade, file) {
    return async (dispatch, getState) => {
        dispatch(imageUploadRequest(upgrade));
        const data = new FormData();
        data.append("file", file);
        try {
            const { auth, env } = selectRequestParameters(getState());
            await makeRequest("PUT", `/secure/upgrades/${upgrade.id}/station-map`, auth, env, data);
            dispatch(imageUploadSuccess(upgrade));
        } catch (error) {
            console.error(error);
            dispatch(imageUploadError(upgrade, error));
        }
    };
}

// -----------------
// Selectors
const selectUpgradeRaw = (id, state) => state.upgrade.upgrades[id];
export const selectUpgrade = createSelector(
    selectUpgradeRaw,
    upgrade => (upgrade ? upgrade.data : null)
);
export const selectUpgradeRequesting = createSelector(selectUpgradeRaw, upgrade => {
    if (upgrade && upgrade.success) return false;
    return true;
});

export const selectCurrentUpgradeId = state => state.upgrade.currentUpgrade;
const selectCurrentUpgradeRaw = state =>
    (state.upgrade.upgrades || {})[selectCurrentUpgradeId(state)];
export const selectCurrentUpgrade = createSelector(
    selectCurrentUpgradeRaw,
    upgrade => (upgrade ? upgrade.data : null)
);
export const selectCurrentUpgradeRequesting = createSelector(selectCurrentUpgradeRaw, upgrade => {
    if (upgrade && upgrade.success) return false;
    return true;
});
export const selectStationMapUploading = state =>
    state.upgrade.stationMap && state.upgrade.stationMap.uploading;
