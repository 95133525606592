import React from "react";

import styles from "./editable-header.module.scss";

import { ReactComponent as IconPencil } from "../icons/pencil.svg";
import { ReactComponent as IconCheck } from "../icons/check.svg";
import { ReactComponent as IconCross } from "../icons/cross.svg";

class EditableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            value: "",
        };
    }

    renderEditing() {
        return (
            <div className={styles.container}>
                <input
                    value={this.state.value}
                    onChange={e => this.setState({ value: e.currentTarget.value })}
                />
                <button
                    className={styles.button}
                    onClick={() =>
                        this.setState(
                            {
                                editing: false,
                            },
                            this.props.onChange(this.state.value)
                        )
                    }
                >
                    <IconCheck />
                </button>
                <button
                    className={styles.button}
                    data-negative={true}
                    onClick={() =>
                        this.setState({
                            editing: false,
                            value: "",
                        })
                    }
                >
                    <IconCross />
                </button>
            </div>
        );
    }

    renderHeader() {
        return (
            <div className={styles.container}>
                <h3>{this.props.value}</h3>
                <button
                    className={styles.button}
                    onClick={() =>
                        this.setState({
                            editing: true,
                            value: this.props.value || "",
                        })
                    }
                >
                    <IconPencil className={styles.iconPencil} />
                </button>
            </div>
        );
    }

    render() {
        return this.state.editing ? this.renderEditing() : this.renderHeader();
    }
}

export default EditableHeader;
