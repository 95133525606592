import React, { Component } from "react";
import { connect } from "react-redux";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./scroll-to-top";
import layout from "../../style/layout.module.scss";

import UpgradePage from "../upgrade";
import PanoramaPage from "../panorama";

class Root extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div className={layout.page}>
                        <Switch>
                            <Route path="/" exact component={UpgradePage} />
                            <Route path="/panoramas/:panoramaId" exact component={PanoramaPage} />
                        </Switch>
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);
